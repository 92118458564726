.eventsWrapper {
  padding: 0 10px;
  margin-left: 10%;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 100px;
  gap: 10px;
  font-size: 18px;
}

.eventName {
  text-align: center;
  margin: 0;
  color: var(--white);
  font-weight: 500;
}

@media only screen and (min-width: 600px) and (max-width: 1280px) and (orientation: landscape) {
  .eventsWrapper {
    font-size: 24px;
    gap: 12px;
  }
}

@media only screen and (min-width: 768px) and (min-height: 1024px) {
  .eventName {
    font-size: 40px;
    gap: 15px;
  }

  .section {
    height: 100%;
  }
}
