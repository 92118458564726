:root {
  --min-height: 2vh;
  --max-height: 2vh;
  --min-scale: .9;
  --max-scale: 1;
}

.section {
  justify-content: space-between;
  overflow: hidden;
  height: 100vh;
}

.section::before {
  content: '';
  z-index: 2;
  position: absolute;
  left: 0;
  width: 100%;
  background-size: contain;
  background-repeat: repeat no-repeat;
  filter: brightness(0.7);
  background-image: url("../../images/blinks-top.png");
  top: 19vh;
  height: 250px;
}

.section::after {
  z-index: 2;
}

.previewImage {
  object-fit: contain;
  width: 70%;
  transform: translateY(-8vh);
  animation: pulse 8s infinite;
}

.yearImage {
  width: 97vw;
  flex: 1 1;
  align-self: baseline;
  object-fit: contain;
  position: absolute;
  top: clamp(150px, 27vh, 190px);
}

.video {
  width: 100%;
  z-index: 1;
}

@media only screen and (max-height: 670px) {
  .video {
    transform: translateY(-6vh);
  }
}

@media only screen and (min-height: 1024px) {
  .section {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .section::before {
    top: 0;
    height: 40vh;
  }

  .section::after {
    height: 300px;
  }

  .yearImage {
    position: relative;
    width: 90vw;
    max-width: 750px;
    transform: translateY(-7vh);
    top: 0;
    align-self: auto;
    animation: slide1 3s ease-in-out 40s forwards;
  }

  .video {
    position: absolute;
    width: 100%;
    animation: slide 5s ease-in-out 3s forwards;
    height: 90vh;
    background: black;
  }

}

@media screen and (min-width: 600px) and (orientation: landscape) {
 .section {
   flex-direction: row;
   align-items: center;
 }

  .section::before {
    top: 0;
    height: 40vh;
  }

  .section::after {
    height: 300px;
  }

  .yearImage {
    position: static;
    width: 50%;
    height: 80vh;
  }

  .video {
    width: 49%;
  }
}

@keyframes slide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0;
  }
}

@keyframes slide1 {
  from {
    display: none;
  }
  to {
    display: block;
  }
}


@keyframes pulse {

  0% {
    transform: scale(var(--min-scale)) translateY(var(--min-height));
  }
  15% {
    transform: scale(var(--max-scale)) translateY(var(--max-height));
  }
  30% {
    transform: scale(var(--min-scale)) translateY(var(--min-height));
  }
  45% {
    transform: scale(var(--max-scale)) translateY(var(--max-height));
  }
  100% {
    transform: scale(var(--min-scale)) translateY(var(--min-height));
  }
}
