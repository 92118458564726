.wishesWrapper {
  gap: 20px;
  justify-items: start;
  overflow: hidden;
}

.wishesWrapper h1 {
  margin: 0;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.textarea,
.input {
  background: transparent;
  color: var(--white);
  border: 1px solid var(--white);
  border-radius: 10px;
  font-size: 18px;
  padding: 5px;
  /*margin: 0 10px;*/
}

.textarea {
  height: 250px;
}

.wishesWrapper label {
  position: relative;
  display: flex;
  flex-direction: column;
  /*width: 100%;*/
  gap: 10px;
  font-size: 20px;
  padding: 0 10px;
}

.wishList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 10vw);
  padding-left: 10vw;
  height: 100%;
  overflow: auto;
  padding-bottom: 100px;
}

.wishWrapper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
  width: 80%;
  background: linear-gradient(300deg, hsla(0, 0%, 50%, .4) 55%, hsla(40, 62%, 65%, .45) 100%);
  gap: 10px;
}

.wishesWrapper p {
  margin: 0;
}

.button {
  background: transparent;
  padding: 5px;
  display: flex;
}

.wishWrapper__text {
  margin: 0;
  font-weight: 700;
  line-height: 1.7;
}

.wishWrapper:nth-child(even) {
  align-self: flex-end;
}

.nameWrapper {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  color: var(--gold);
}

.name {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

@media screen and (min-width: 768px) {
  .wishesWrapper {
    width: 100%;
    max-width: 800px;
    font-size: 24px;
    gap: 30px;
    /*margin: auto auto 300px;*/
  }

  .wishesWrapper > button {
    font-size: 28px;
  }
}


