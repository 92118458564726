.section {
  margin-bottom: 100px;
  padding-left: 7vw;
  width: calc(100% - 7vw);
}

.timelineWrapper {
  display: flex;
  flex-direction: column;
  /*gap: 40px;*/
  align-items: center;
  justify-content: center;
  /*backdrop-filter: contrast(0.65);*/
  border-radius: 30px;
  padding: 0 10px 20px 10px;
  overflow: hidden;
}

.modalDescription {
  /*backdrop-filter: contrast(0.65);*/
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  padding: 0 30px;
}

.yearWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.year {
  color: var(--gold);
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  border: 1px solid;
  padding: 5px 10px;
  border-radius: 20px;
  background: var(--black);
  margin-top: 30px;
  max-width: 400px;
}

.itemList {
  display: flex;
  flex-direction: column;
}

.itemWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  align-items: center;
  position: relative;
  padding-bottom: 30px;
}

.yearWrapper:nth-child(even) .itemWrapper {
  flex-direction: row-reverse;
}

/*.itemWrapper:nth-child(even) {*/
/*  flex-direction: row-reverse;*/
/*}*/

.text {
  width: 100%;
  /*color: var(--gold);*/
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  /*text-align: justify;*/
  /*padding: 0 10px;*/
}

.video {
  min-height: 30vh;
  z-index: -3;
  object-fit: contain;
  width: 100%;
}

.fullSizeWrapper {
  display: flex;
  flex-direction: column;
  /*gap: 20px;*/
  padding: 10px;
  justify-content: flex-start;
  height: 98vh;
  overflow-y: auto;
}

.fullSizeWrapper img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.textSummary {
  text-align: justify;
  line-height: 1.5;
  border-top: 1px solid white;
  padding-top: 15px;
}

.image {
  border-radius: 20px;
  width: 46%;
}

.previewImage {
  object-fit: contain;
  width: 70%;
  transform: translateY(2vh);
  animation: pulse 8s infinite;
}

.line {
  position: absolute;
  left: 50%;
  height: calc(100% - 0px);
  border-left: 2px solid var(--gold);
}

.description {
  white-space: pre-wrap;
}

.description p {
  margin: 0 0 10px;
}

.header {
  margin: 0;
  font-size: 24px;
  text-align: center;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 10px;
}

i {
  align-self: flex-end;
}

.descriptionWrapper p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
}

.tip {
  padding: 0;
  color: gray;
  font-size: 14px;
}

.modalDescription .description {
  margin-bottom: 40px;
}

.link {
  text-decoration: underline;
  color: var(--gold);
}

@media screen and (max-width: 375px) and (orientation: portrait) {
  .line {
    display: none;
  }

  .itemWrapper,
  .yearWrapper:nth-child(even) .itemWrapper {
    flex-direction: column;
  }

  .image, .imagePlug {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {

  .itemWrapper {
    gap: 50px;
  }

  .year {
    font-size: 32px;
    padding: 5px 15px;
    max-width: 400px;
  }

  .descriptionWrapper, .textSummary, .fullSizeWrapper p {
    font-size: 28px;
  }

  .header {
    font-size: 50px;
  }

  .fullSizeWrapper img {
    max-height: 90vh;

    @media screen and (orientation: landscape) {
      max-height: 70vh;
    }
  }

  .fullSizeWrapper p {
    /*text-align: center;*/
  }

  .tip {
    font-size: 20px;
  }

  .description p {
    margin: 0 0 20px;
  }

  .modalDescription {
    align-items: center;
  }
}


@media only screen and (min-width: 600px) and (max-width: 1280px) and (orientation: landscape) {
  .fullSizeWrapper {
    align-items: center;
  }

  .fullSizeWrapper > .image-gallery-slide .image-gallery-image{
    max-width: 80vw;
  }

  .tip {
    font-size: 20px;
  }
}

@media only screen and (min-height: 1024px) and (orientation: portrait) {
  .fullSizeWrapper {
    justify-content: start;
  }

  .fullSizeWrapper img {
    max-height: 42vh;
  }

  .fullSizeWrapper p {
    /*text-align: center;*/
    font-size: 32px;
  }

  .tip {
    font-size: 20px;
  }
}
