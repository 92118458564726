.h1 {
  margin: 0;
  width: 87vw;
  text-align: center;
  border-bottom: 1px var(--gold);
  border-bottom-style: solid;
  font-family: "Didact Gothic", sans-serif;
}

.section::after {
  filter: brightness(0.4);
}

.menuWrapper {
  /* -webkit-backdrop-filter: contrast(.65);
  backdrop-filter: contrast(0.65); */
  border-radius: 30px;
  padding: 20px 10px;
  margin-bottom: 40px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-auto-rows: minmax(20px, auto);
  justify-content: center;
  grid-gap: 40px; */
}

.dishWrapperStyles {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /*gap: 10px;*/
}

.typeWrapperStyles {
  display: flex;
  align-items: center;
  flex-direction: column;
  /*gap: 15px;*/
}

.typeHeaderStyles {
  text-transform: capitalize;
  font-family: "Didact Gothic", sans-serif;
  margin: 0;
  /*border-top: 1px solid var(--gold);*/
  width: 100%;
  text-align: center;
  font-weight: 900;
  letter-spacing: 5px;

}

.dishNameStyle {
  margin: 0;
  color: var(--white);
  font-weight: 500;
}

.yearImage {
  width: 50vw;
  transform: translateY(-35px);
}

@media only screen and (min-width: 800px) {
  .dishNameStyle {
    font-size: 32px;
  }

  .typeHeaderStyles {
    font-size: 40px;
  }
}
