.section {
  gap: 40px;
  max-width: 400px;
  height: 85vh;
  padding-left: 10vw;
}

.heading {
  text-align: center;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.heading h2 {
  margin: 0;
}

.description {
  text-align: center;
  margin: 0;
  font-size: 20px;
}

.contactForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

label {
  position: relative;
  display: flex;
  flex-direction: column;
  /*width: 100%;*/
  gap: 10px;
  font-size: 20px;
  /*padding: 0 10px;*/
}

.input {
  background: transparent;
  color: var(--white);
  border: 1px solid var(--white);
  border-radius: 10px;
  font-size: 18px;
  padding: 5px;
}

.link {
  text-decoration: underline;
  text-align: center;
  color: var(--gold);
}
.dd {
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  height: 100vh;
  gap: 10px;
}

.dd1 {
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
  height: 100vh;
  gap: 10px;
  margin-top: 70vh;
}

.image {
  border-radius: 20px;
  width: 46%;
}

.previewImage {
  object-fit: contain;
  width: 70%;
  transform: translateY(2vh);
  animation: pulse 8s infinite;
}

.video {
  /*min-height: 30vh;*/
  /*z-index: -3;*/
  object-fit: contain;
  width: 100%;

  max-height: 90vh;
}

.header {
  margin: 0;
  text-align: center;
}

.greating {
  color: var(--gold);
  text-align: center;
  max-width: 300px;
  font-size: 20px;
}

.section button {
  margin: 0;
}

@media screen and (min-width: 600px) and (orientation: landscape) {
  .section {
    max-width: 800px;
  }
  .heading,
  .contactForm {
    flex: 1;
  }

}

@media screen and (min-width: 768px) {

  .contactForm {
    gap: 30px;
  }

  .section {
    max-width: 700px;
  }

  .dd1 {
    margin-top: 90vh;
  }
  .heading {
    font-size: 42px;
  }

  .description , label {
    font-size: 28px;
  }

  .input, .section button {
    font-size: 32px;
  }

  .greating {
    max-width: 35vw;
    font-size: 32px;
    width: 50%;
  }
}

@media only screen and (min-height: 912px) {
  .greating {
    max-width: 50vw;
  }
}
