@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Jost&family=Didact+Gothic&display=swap');
@import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --gold: #ddb86d;
  --black: #000000;
  --white: #ececec;
  --green: rgba(22, 75, 43, 0.6);
}


* {
  /* font-family: 'Montserrat', sans-serif; */
  font-family: "Jost", sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--black);
  color: var(--white);
  position: relative;
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

main {
  scroll-behavior: smooth;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  background: transparent;
  border: none;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*padding-left: 10vw;*/
  width: calc(100% - 10vw);
}

section.main {
  width: 100%;
  padding: 0;
}

.bg-bottom-image {
  position: absolute;
  object-fit: cover;
  object-position: top center;
  width: 100%;
  bottom: 0;
  /*rotate: 180deg;*/
  z-index: 0;
  height: 170px;
  filter: brightness(0.5);
}

.little-close-btn {
  background: var(--black);
  border: 1px solid var(--white);
  border-radius: 50%;
  padding: 5px;
  display: flex;
  position: absolute;
  right: 15px;
  top: 10px
}

.big-close-btn {
  padding: 10px;
  color: var(--white);
  border: 1px solid gray;
  border-radius: 10px;
  text-align: center;
  font-size: 20px;
  /*background: #43494e;*/
  margin: 0 10px;
  display: flex;
  gap: 20px;
  align-items: center;
  line-height: 1;
  justify-content: center;
}

.big-close-btn:disabled {
  color: gray;
  cursor: not-allowed;
  border-color: gray;
}

.modal {
  position: fixed;
  top: 0;
  z-index: 10;
  background: var(--black);
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.modal header {
  display: flex;
  align-items: center;
  height: 55px;
  background: var(--black);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 10px;
}

section::after,
#loader-container::after {
  content: '';
  z-index: -1;
  position: absolute;
  left: 0;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(0.6);
  background-image: url("./images/bottom.png");
  bottom: 0;
  height: clamp(140px, calc(100vh - 600px), 300px);
  background-position: top center;
}

#loader-container::before,
#loader-container::after
  /*main */
{
  animation-duration: 3s;
  animation-name: fadeIn;
}

#loader-container::before {
  content: '';
  z-index: 2;
  position: absolute;
  left: 0;
  width: 100%;
  background-size: contain;
  background-repeat: repeat no-repeat;
  filter: brightness(0.7);
  background-image: url("./images/blinks-top.png");
  top: 0;
  height: 40vh;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  /*background: #3d3d3d;*/
  z-index: 9;
  position: sticky;
  top: 0;
  animation-duration: 3s;
  animation-name: fadeIn;
}

.header img {
  width: 32px;
  height: 32px;
}


/* Media Query for Tablets Ipads portrait mode */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .header img {
    width: 42px;
    height: 42px;
  }
}


@media screen and (min-width: 768px) {
  section::after {
    height: 300px;
  }
}

@media only screen and (min-height: 1024px) {
  .desserts {
    grid-column: span 2;
  }

}

@media screen and (min-width: 600px) and (max-width: 1280px) and (orientation: landscape) {
  .desserts {
    grid-area: 2/3;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.hh {
  margin: 0;
  padding: 0;
  font-size: 8.5vh;
  font-family: "Jost", sans-serif;
  z-index: -1;
  color: gray;
  font-weight: bolder;
  position: fixed;
  transform: rotate(270deg) translateX(-7ch) translateY(-46vw);
  top: 0;
  text-transform: uppercase;
  opacity: .7;
  letter-spacing: .5ch;

  @media screen and (orientation: landscape) {
    font-size: 10vh;
    transform: rotate(270deg) translateX(-7ch) translateY(-47vw);
  }

  @media only screen and (max-width: 300px) {
    font-size: 5vh;
  }

  /*IPhone XR*/
  @media only screen and (min-height: 896px) {
    font-size: 9vh;
  }

  /*tablets*/
  @media only screen and (min-width: 767px) and (max-width: 1024px) and (orientation: portrait) {
    font-size: 9vh;
    transform: rotate(270deg) translateX(-7ch) translateY(-46.5vw);
  }
}

@media screen and (min-width: 768px) {
  .header > button {
    display: none;
  }

  .image-gallery-slide .image-gallery-image {
    max-width: 80vh;
  }
}

.seats {
  align-items: center;
  justify-content: start;
  touch-action: auto;
}

.seats img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  object-fit: contain;
}

@media only screen and (min-width: 1024px) {
  .seats {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .seats img {
    width: 50%;
    height: 92vh;
  }
}

.tip {
  color: gray;
  text-align: center;
  width: 100%;
  margin: 0;
}

.image-gallery-slides {
  min-width: 34vw;
}

.image-gallery-slide .image-gallery-image {
  border-radius: 10px;
  max-width: 80vw;
}

.photoWrapper > .image-gallery-slide .image-gallery-image{
  max-width: 50vw;
}

@media only screen and (min-width: 450px) and (max-width: 600px){
  .image-gallery-slides {
    min-width: 38vw;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1280px) and (orientation: landscape) {
  .image-gallery-slides {
    min-width: 40vw;
  }
}

@media only screen and (min-height: 1024px) {
  .image-gallery-slides {
    min-width: 40vw;
  }
}
